import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { toggleLoading, setAppError } from './app'
import { GET_CLAIM_DEVICES_TASKS } from '../constants'

export const getClaimDevicesTasks = createAsyncThunk(
  'tasks/getClaimDevicesTasks',
  async (payload, thunkAPI) => {
    const { dispatch } = thunkAPI
    const { claimId } = payload
    try {
      const response = await axios.get(`${GET_CLAIM_DEVICES_TASKS}${claimId}/`)
      return response.data.data
    } catch (error) {
      dispatch(
        setAppError({ error: true, message: 'Error on claim devices tasks' })
      )
    }
  }
)

export const initialState = {
  list: [],
}
export const tasksStore = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    addTasks: (state, action) => {
      state.list = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClaimDevicesTasks.pending, (state, action) => {})
    builder.addCase(getClaimDevicesTasks.fulfilled, (state, action) => {
      const { payload } = action
      state.list = payload
    })
    builder.addCase(getClaimDevicesTasks.rejected, (state, action) => {})
  },
})

export const { addTasks } = tasksStore.actions

export default tasksStore.reducer
