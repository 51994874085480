import React from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import IconTrash from '../icons/icon-trash-red'
import IconSuccess from '../icons/icon-success'
import IconAlert from '../icons/icon-alert'
import Spinner from '../spinner'

export function ImagePreview({
  className,
  id,
  loading,
  name,
  src,
  onDelete,
  deletable,
  disabled,
  type,
  size,
  file,
  documentType,
  approved,
  ...props
}) {
  const onDeleteImage = () => {
    onDelete(id, name, documentType, type)
  }

  const getSrc = () => {
    if (/image/.test(type)) return src
    if (/application/.test(type)) return '/images/file.png'
    if (/video/.test(type)) return '/images/video.png'
    return '/images/image.png'
  }

  return (
    <div className={c('image-preview', className, { loading })} {...props}>
      {approved === true && (
        <div className="image-preview_status">
          <IconSuccess />
        </div>
      )}
      {approved === false && (
        <div className="image-preview_status">
          <IconAlert />
        </div>
      )}
      <div className="image-preview__data">
        {loading ? (
          <>
            <Spinner className="image-preview__spinner" />
            <div className="image-preview__name">{name}</div>
          </>
        ) : null}
        <a href={src} target="_blank" rel="noreferrer">
          <img
            className="image-preview__img"
            alt="preview upload proof"
            src={getSrc()}
          />
        </a>
        {!disabled && deletable ? (
          <button
            title={loading ? 'Cancel' : 'Delete'}
            type="button"
            onClick={onDeleteImage}
            className="image-preview__delete"
          >
            <IconTrash />
          </button>
        ) : null}
      </div>
    </div>
  )
}
export default ImagePreview

ImagePreview.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  loading: PropTypes.bool,
  img: PropTypes.string,
  src: PropTypes.string,
  file: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.number,
  name: PropTypes.string,
  onDelete: PropTypes.func,
  deletable: PropTypes.bool,
  disabled: PropTypes.bool,
  documentType: PropTypes.string,
  approved: PropTypes.bool,
}

ImagePreview.defaultProps = {
  className: '',
  id: Date.now(),
  loading: false,
  file: false,
  type: '',
  size: 0,
  deletable: true,
  disabled: true,
  img: undefined,
  src: undefined,
  name: '',
  documentType: '',
  onDelete: () => {},
  approved: null,
}
