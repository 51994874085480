import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectCategories } from '../../selectors/item-categories'
import ItemCategoryItem from '../item-category-item'
import { AFFECTED_ITEMS_PATH } from '../../constants'
import { selectClaimID } from '../../selectors/claim'
import { selectFeatureFlagByName } from '../../selectors/feature-flags'
import { selectDeviceRestrictions } from '../../selectors/restrictions'

export function ItemCategories({ className, ...props }) {
  const categories = useSelector(selectCategories)
  const claimID = useSelector(selectClaimID)
  const deviceRestrictions = useSelector(selectDeviceRestrictions)
  const featureFlagRestrictions = useSelector((_) =>
    selectFeatureFlagByName(_, 'device_restrictions')
  )
  const [showNoCategoriesError, setShowNoCategoriesError] = useState(false)

  const allowedCategories = categories.filter((category) => {
    if (deviceRestrictions != null && featureFlagRestrictions) {
      const selectedCategory = deviceRestrictions.item_categories?.find((cat) => {
        return cat.id === category.id
      })
      if(selectedCategory == null || selectedCategory.quantity_allowed === 0 || selectedCategory.remainder === 0) {
        return false
      }
      return true
    }})

  useEffect(() => {
    if(allowedCategories.length === 0) {
      setShowNoCategoriesError(true)
    }
  }, [allowedCategories])

  return (
    <>
      {showNoCategoriesError && (
        <div>
          <div className="modal show" style={{ display: 'block' }}>
            <div
              style={{ maxWidth: '48rem' }}
              className="mt-4 mt-lg-5 mb-5 bg-white border rounded shadow-sm mx-auto"
            >
              <div className="p-3 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Unable to Add Device to Account</h3>
              </div>
              <hr className="opacity-10" />
              <div className="p-3">
                <div>
                  <div className="mb-4">
                    You’ve reached the maximum limit of devices which can be added to your account. Please{' '}
                    <span className="text-primary text-decoration-underline" style={{"cursor": "pointer"}} onClick={() => window.Intercom('show')}>contact us</span>
                    {' '}if you need assistance making updates to your account.
                  </div>
                </div>
              </div>
              <hr className="opacity-10" />
              <div className="p-3 d-flex flex-wrap justify-content-end">
                <div className="me-3">
                  <Link
                    to={`/${AFFECTED_ITEMS_PATH}/${claimID || ''}`}
                    className="btn btn-outline-primary"
                  >
                    Go Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show" />
        </div>
      )}
      <div className={c('item-categories', className)} {...props}>
        <div className="row">
          {allowedCategories.map((category) => {          
            return (
              <div key={category.id} className="col-md-6 col-12 mb-4">
                <ItemCategoryItem category={category} />
              </div>
            )
          })}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="item-categories_btns">
              <Link
                to={`/${AFFECTED_ITEMS_PATH}/${claimID || ''}`}
                className="btn btn-outline-primary"
              >
                Go Back
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ItemCategories

ItemCategories.propTypes = {
  className: PropTypes.string,
}

ItemCategories.defaultProps = {
  className: '',
}
