import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getContract, getUserData, listClaims } from '../reducers/user'
import { getUserDevices } from '../reducers/devices'
import { getLossTypes } from '../reducers/losstypes'
import { getCountries } from '../reducers/countries'
import { getCurrencies } from '../reducers/currencies'
import { getItemOperabilities } from '../reducers/item-operabilities'
import { getItemCategories } from '../reducers/item-categories'
import { getMobileOperators } from '../reducers/mobile-operators'
import { toggleLoading, setAppError } from '../reducers/app'
import { getPurchaseConditions } from '../reducers/purchase-conditions'
import { getPartner } from '../reducers/partner'
import { getFeatureFlags } from '../reducers/feature-flags'
import { getClaimLimits } from '../reducers/claim/thunks'
import { getDeviceRestrictions } from '../reducers/restrictions'

export function useInitialRequest() {
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchInitialData() {
      try {
        dispatch(toggleLoading(true))
        await dispatch(getUserData())
        await dispatch(getPartner())
        await dispatch(getLossTypes())
        await dispatch(getUserDevices())
        await dispatch(getCountries())
        await dispatch(getCurrencies())
        await dispatch(getItemOperabilities())
        await dispatch(getItemCategories())
        await dispatch(getMobileOperators())
        await dispatch(getPurchaseConditions())
        await dispatch(listClaims())
        await dispatch(getFeatureFlags())
        await dispatch(getContract())
        await dispatch(getClaimLimits())
        await dispatch(getDeviceRestrictions())
        dispatch(toggleLoading(false))
      } catch (error) {
        dispatch(
          setAppError({ error: true, message: 'Error on Authentication' })
        )
        dispatch(toggleLoading(false))
      }
    }
    fetchInitialData()
  }, [])
  return null
}

export default useInitialRequest
