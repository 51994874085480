import c from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../selectors/countries'
import { selectUserAddress } from '../../selectors/user'
import { defaultProps, propTypes } from './props'
import { CLEAR_PRODUCTION, CLEAR_STAGING } from '../../constants'

async function getCoordinatesFromZip(street, zipCode, country) {
  const API_KEY = process.env.REACT_APP_GM_KEY
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${street}&components=country:${country}|postal_code:${zipCode}&key=${API_KEY}`

  try {
    const response = await fetch(url)
    if (!response.ok) {
      return { lat: null, lng: null }
    }
    const data = await response.json()

    if (data.results && data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location
      return { lat, lng }
    }
    return { lat: null, lng: null }
  } catch (error) {
    return { lat: null, lng: null }
  }
}

export function PartnerRepairShop({
  className,
  label,
  instruction,
  prefill
}) {
  const userAddress = useSelector(selectUserAddress)
  const [latLng, setlatLng] = useState([null, null])
  const countries = useSelector(selectCountries)

  useEffect(() => {
    async function fetchCoords() {
      try {
        const data = await getCoordinatesFromZip(
          userAddress.street, 
          userAddress.zip, 
          countries?.find(country => country.id === userAddress.country_id)?.code ?? "US"
        )
        setlatLng([data.lat, data.lng])
      } catch (error) {
        setlatLng([null, null])
      }
    }
    fetchCoords()
  }, [userAddress, countries])

  const hostUrl = window.location.origin
  if (
    hostUrl === CLEAR_STAGING || hostUrl === CLEAR_PRODUCTION
  ) {
    return (
      <div className={c('repair-shops', className)}>
        {!isEmpty(label) && <div className="dc-label">{label}</div>}
        <div className="mb-1">

            <a
              target="_blank"
              href={`https://www.google.com/maps/d/u/1/viewer?${
                latLng[0] && latLng[1] ? `ll=${latLng[0]},${latLng[1]}&z=10&` : 'll=28.030786435209485%2C-81.6504780200426&z=8&'
              }mid=1i3cNUqOhoBrCulDo-eil9DcFO2JXguzS`}
              className="btn btn-primary"
              rel="noreferrer"
            >
              {prefill || 'Nearest repair shops'}
            </a>
        </div>
        {!isEmpty(instruction) && (
          <div className="dc-instruction">{instruction}</div>
        )}
      </div>
    )
  }
  return <div />
}
export default PartnerRepairShop

PartnerRepairShop.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
}

PartnerRepairShop.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
}
