import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import c from 'classnames'
import Select from 'react-select'

export function ItemType({
  className,
  itemId,
  options,
  onChange,
  current,
  disabled,
  ...props
}) {
  return (
    <div className={c('item-type-input', className)} {...props}>
      <Select
        isDisabled={disabled}
        className="multiselect"
        placeholder="Select Item Type"
        options={options.map((o) => ({ value: o.id, label: o.name }))}
        onChange={onChange}
        value={isEmpty(current.value) ? null : current}
      />
    </div>
  )
}
export default ItemType

ItemType.propTypes = {
  className: PropTypes.string,
  itemId: PropTypes.number,
  current: PropTypes.object,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
}

ItemType.defaultProps = {
  className: '',
  itemId: Date.now(),
  disabled: false,
  current: {},
  options: [],
  onChange: () => {},
}
