import { createSelector } from '@reduxjs/toolkit'

export const countriesStore = (state) => state.countries

export const selectCountries = createSelector(
  countriesStore,
  (countries) => countries.countries
)

export const selectCountryIdNames = createSelector(
  selectCountries,
  (countries) => countries.map((c) => ({ id: c.id, name: c.name }))
)

export const selectCountryForSelec = createSelector(
  selectCountries,
  (countries) => countries.map((c) => ({ value: c.id, label: c.name }))
)
