import React, { useEffect } from 'react'
import {
  Routes,
  Route,
  useSearchParams,
  useNavigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import DescribeIncident from './pages/describe-incident'
import AffectedItems from './pages/affected-items'
import TypeLoss from './pages/type-loss'
import ProofLoss from './pages/proof-loss'
import ConfirmAddress from './pages/confirm-address'
import ReviewClaim from './pages/review-claim'
import ClaimDetailPage from './pages/claim-detail'
import DeviceDetail from './pages/device-detail'
import Error from './pages/error'
import NavigationMobile from './pages/navigation-mobile'
import Login from './pages/login'
import Home from './pages/home'
import Nothing from './pages/nothing'
import AddItem from './pages/add-item'
import ResetPassword from './pages/reset-password'
import InternalLayout from './components/layouts/internal'
import DetailLayout from './components/layouts/detail'
import HomeLayout from './components/layouts/home'
import NoAuth from './components/layouts/no-auth'
import Auth from './components/layouts/auth'
import {
  DESCRIBE_INCIDENT_PATH,
  AFFECTED_ITEMS_PATH,
  ADD_ITEMS_PATH,
  TYPE_LOSS_PATH,
  PROOF_LOSS_PATH,
  REVIEW_CLAIM_PATH,
  CLAIM_DETAIL_PATH,
  ERROR_PATH,
  NAVIGATION_PATH,
  LOGIN_PATH,
  PRODUCTION,
  ADD_ITEM_DETAILS_PATH,
  RESET_PASSWORD_PATH,
  CONFIRM_ADDRESS_PATH,
} from './constants'
import { useBranding } from './hooks/branding'
import { setToken } from './reducers/user'
import Notification from './components/notification'
import ItemCategories from './components/item-categories'
import ItemDetails from './components/item-detail'
import {
  selectPartnerBranding,
  selectPartnerPermissionValueByScope,
} from './selectors/partner'
import {
  ALLOW,
  PARTNER_CLAIM_ADD_ITEM,
  PARTNER_CLAIM_BRANDING,
} from './constants/permissions'
import { selectBranding } from './selectors/app'

Sentry.init({
  dsn: 'https://a6960dcd1c30dfa68ec5e6edb1b2917d@o537383.ingest.sentry.io/4506740013924352',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'plume.akko.app',
    'claims.akko.app',
    'staging.claims.akko.app',
    `${process.env.REACT_APP_API}`,
  ],
  environment: process.env.REACT_APP_ENV || 'development',
})

function App() {
  const [searchParams] = useSearchParams()
  const localToken = localStorage.getItem('token')
  const paramToken = searchParams.get('t')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // eslint-disable-next-line max-len
  const addItemPermision = useSelector((state) =>
    selectPartnerPermissionValueByScope(state, PARTNER_CLAIM_ADD_ITEM)
  )
  const brandingPermision = useSelector((state) =>
    selectPartnerPermissionValueByScope(state, PARTNER_CLAIM_BRANDING)
  )
  const branding = useSelector(
    brandingPermision === ALLOW ? selectPartnerBranding : selectBranding
  )
  const [styles] = useBranding()

  const location = useLocation()

  // note: this happens only one time in life cycle
  useEffect(() => {
    if (paramToken) {
      localStorage.setItem('token', paramToken)
      dispatch(setToken({ token: paramToken }))
    } else if (localToken) {
      dispatch(setToken({ token: localToken }))
    } else if (location.pathname.indexOf(RESET_PASSWORD_PATH) < 0) {
      navigate(LOGIN_PATH)
    }
  }, [])
  return (
    <div>
      <Helmet>
        <title>{branding.brandName}</title>
        <link rel="icon" href={branding.tabIconUrl} />
        <style>{styles}</style>
      </Helmet>
      <Helmet>
        {process.env.REACT_APP_ENV === PRODUCTION ? (
          <script type="text/javascript">
            {`
              window.__lo_site_id = 329866;
              (function() {
              var wa = document.createElement('script'); wa.type = 'text/javascript'; wa.async = true;
              wa.src = 'https://d10lpsik1i8c69.cloudfront.net/w.js';
              var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(wa, s);
              })();
            `}
          </script>
        ) : null}
      </Helmet>
      <Notification />
      <Routes>
        <Route path="/" element={<NoAuth />}>
          <Route path={NAVIGATION_PATH} element={<NavigationMobile />} />
          <Route path={ERROR_PATH} element={<Error />} />
          <Route path={LOGIN_PATH} element={<Login />} />
          <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
        </Route>
        <Route path="/" element={<Auth />}>
          <Route path="/" element={<HomeLayout />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="/" element={<InternalLayout />}>
            <Route path={`${AFFECTED_ITEMS_PATH}/`} element={<AffectedItems />}>
              <Route path=":claimId" element={<AffectedItems />} />
            </Route>
            {addItemPermision === ALLOW ? (
              <Route path={`${ADD_ITEMS_PATH}/`} element={<AddItem />}>
                <Route index element={<ItemCategories />} />
                <Route
                  path={`:itemCategory/${ADD_ITEM_DETAILS_PATH}`}
                  element={<ItemDetails />}
                />
                <Route
                  path={`:itemCategory/${ADD_ITEM_DETAILS_PATH}/:itemId`}
                  element={<ItemDetails />}
                />
              </Route>
            ) : null}
            <Route path={`${TYPE_LOSS_PATH}/:claimId`} element={<TypeLoss />} />
            <Route
              path={`${DESCRIBE_INCIDENT_PATH}/:claimId`}
              element={<DescribeIncident />}
            />
            <Route
              path={`${PROOF_LOSS_PATH}/:claimId`}
              element={<ProofLoss />}
            />
            <Route
              path={`${CONFIRM_ADDRESS_PATH}/:claimId`}
              element={<ConfirmAddress />}
            />
            <Route
              path={`${REVIEW_CLAIM_PATH}/:claimId`}
              element={<ReviewClaim />}
            />
            <Route path="*" element={<Nothing />} />
          </Route>
          <Route path={CLAIM_DETAIL_PATH} element={<DetailLayout />}>
            <Route path=":claimId" element={<ClaimDetailPage />} />
            <Route
              path=":claimId/devices/:claimDeviceId"
              element={<DeviceDetail />}
            />
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
