import { hexToHSL } from '../libs/hex-hsl'
import { WHITE_LABEL_COLOR } from '../constants'

export function useBranding() {
  const hsl = hexToHSL(WHITE_LABEL_COLOR)
  const styles = `
      :root {
          --theme-color: ${WHITE_LABEL_COLOR};
          --theme-color-h: ${hsl.h};
          --theme-color-s: ${hsl.s}%;
          --theme-color-l: ${hsl.l}%;
          --theme-color-a: 1;
      }
    `
  return [styles]
}
