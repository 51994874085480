import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { DELAY_TIMING } from '../../constants'
import Label from '../label'
import { propTypes, defaultProps } from './props'
import Amount from '../amount'
import { updateDynamicField, setRepairCost } from '../../reducers/claim'
import { selectClaimItemAffectedByID } from '../../selectors/claim'
import Spinner from '../spinner'

export function RepairCost({
  className,
  label,
  instruction,
  handler,
  onChange,
  claimId,
  claimDeviceId,
  deviceId,
  field_state,
  note,
  id: fieldId,
  disabled,
  type,
  ...props
}) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const device = useSelector((state) =>
    selectClaimItemAffectedByID(state, parseInt(claimDeviceId, 10))
  )
  let repCost = ''
  if (type === 'REPAIR_COST_ESTIMATE') {
    repCost = device.repairCostEstimate
  }
  if (type === 'REPAIR_COST_FINAL') {
    repCost = device.repairCost
  }

  const update = useCallback(
    debounce((data) => {
      setLoading(true)
      dispatch(
        updateDynamicField({
          claim_id: claimId,
          claim_device_id: claimDeviceId,
          device_id: deviceId,
          field_id: fieldId,
          handler: 'repair_cost',
          type,
        })
      ).then(() => {
        setLoading(false)
        onChange({ fieldId })
      })
    }, DELAY_TIMING),
    []
  )

  const onInputChange = (e) => {
    const data = {
      claimDeviceId,
      repairCost: e.value ?? null,
      repairCostUnit: e.unit,
      type,
    }
    dispatch(setRepairCost(data))
    update(data)
  }

  return (
    <div className={c('repair-cost-component', className)}>
      {!isEmpty(label) && <div className="dc-label">{label}</div>}
      <div className="mb-1">
        <Amount
          // disabled={field_state === 'REVIEW' || field_state === 'ACCEPTED'}
          onChange={onInputChange}
          initialValue={repCost}
          showUnit={false}
          defaultUnit={device.repairCostUnit}
          disabled={disabled}
        />
      </div>
      {!isEmpty(instruction) && (
        <div className="dc-instruction">{instruction}</div>
      )}
      {/* { loading && <Spinner />} */}
    </div>
  )
}
export default RepairCost

RepairCost.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  instruction: PropTypes.string,
  onChange: PropTypes.func,
  handler: PropTypes.string,
  disabled: PropTypes.bool,
}

RepairCost.defaultProps = {
  ...defaultProps,
  className: '',
  label: '',
  instruction: '',
  onChange: () => {},
  handler: '',
  disabled: false,
}
