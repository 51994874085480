import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { setAppError } from './app'
import { GRAPH, GRAPH_UPDATE, TASK_GRAPHS } from '../constants'

export const getTaskGraphs = createAsyncThunk(
  'graph/getTaskGraphs',
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    try {
      const response = await axios.get(TASK_GRAPHS)
      return response.data.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on get graph' }))
      rejectWithValue(error)
    }
  }
)

export const getGraphData = createAsyncThunk(
  'graph/getGraphData',
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    const { graphId } = payload
    try {
      const response = await axios.get(`${GRAPH}${graphId}/`)
      return response.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on get graph' }))
      rejectWithValue(error)
    }
  }
)
export const updateGraphData = createAsyncThunk(
  'graph/updateGraphData',
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    const { graphId } = payload
    try {
      const response = await axios.post(`${GRAPH_UPDATE}${graphId}/`, payload)
      return response.data
    } catch (error) {
      dispatch(setAppError({ error: true, message: 'Error on update graph' }))
      rejectWithValue(error)
    }
  }
)

export const initialState = {
  graphs: [],
  nodes: [],
  edges: [],
}
export const graphStore = createSlice({
  name: 'graph',
  initialState,
  reducers: {
    updateNodes: (state, action) => {
      state.nodes = action.payload.nodes
    },
    updateEdges: (state, action) => {
      state.edges = action.payload.edges
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTaskGraphs.pending, (state, action) => {})
    builder.addCase(getTaskGraphs.fulfilled, (state, action) => {
      const { payload } = action
      state.graphs = payload
    })
    builder.addCase(getTaskGraphs.rejected, (state, action) => {})

    builder.addCase(getGraphData.fulfilled, (state, action) => {
      const { payload } = action
      state.nodes = payload.nodes
      state.edges = payload.edges
    })

    builder.addCase(updateGraphData.fulfilled, (state, action) => {
      const { payload } = action
      state.nodes = payload.nodes
      state.edges = payload.edges
    })
  },
})

export const { addDevices } = graphStore.actions

export default graphStore.reducer
