import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Select from 'react-select'
import isEmpty from 'lodash/isEmpty'
import DatePicker from 'react-datepicker'
import NumberFormat from 'react-number-format'
import c from 'classnames'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import {
  selectCategoryByCategoryName,
  selectItemTypeByCategoryName,
} from '../../selectors/item-categories'
import {
  selectUserDeviceById,
  selectProccessingItem,
} from '../../selectors/devices'
import { ClearItemDetailSchema, ItemDetailSchema } from './schemas'
import ItemType from './item-type'
import ItemMake from './item-make'
import ItemModel from './item-model'
import Toggle from '../toggle'
import { selectMobileOperatorsForSelector } from '../../selectors/mobile-operators'
import { selectPurchaseConditionsForSelector } from '../../selectors/purchase-conditions'
import {
  ADD_ITEMS_PATH,
  AFFECTED_ITEMS_PATH,
  CLEAR,
  WHITE_LABEL_BRAND_NAME,
} from '../../constants'
import Spinner from '../spinner'
import { addItemDevice, updateItemDevice } from '../../reducers/devices'
import { generateInitialValues } from './utils'
import { selectClaimID } from '../../selectors/claim'
import { selectDeviceRestrictions } from '../../selectors/restrictions'
import { isFieldRequired } from '../../utils/validation'
import { getDeviceRestrictions } from '../../reducers/restrictions'

const ITEM_SCHEMA =
  WHITE_LABEL_BRAND_NAME === CLEAR ? ClearItemDetailSchema : ItemDetailSchema

const markRequiredField = (fieldName) =>
  isFieldRequired(ITEM_SCHEMA, fieldName) ? '*' : ''

export function ItemDetail({ className, edit, ...props }) {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { itemCategory, itemId } = params
  const itemCategoryName = itemCategory.replace('-', ' ')
  const claimID = useSelector(selectClaimID)
  const deviceRestrictions = useSelector(selectDeviceRestrictions)

  const category = useSelector((state) =>
    selectCategoryByCategoryName(state, itemCategoryName)
  )

  const proccessing = useSelector(selectProccessingItem)

  // Parent that controlss child selectors
  const itemTypes = useSelector((state) =>
    selectItemTypeByCategoryName(state, itemCategoryName)
  )
  const [itemMakes, setItemMakes] = useState([])
  const [itemModels, setItemModels] = useState([])
  const [readableError, setReadableError] = useState()

  function validateSelectedItemAttribute(selectedItemAttribute, itemSpecName, label) {
    if (selectedItemAttribute) {
      const itemSpecRestriction = deviceRestrictions[
        `item_${itemSpecName}s`
      ].find((itemSpec) => {
        return itemSpec.id === selectedItemAttribute
      })
      if (itemSpecRestriction) {
        if (itemSpecRestriction.quantity_allowed === 0) {
          setReadableError(
            WHITE_LABEL_BRAND_NAME === CLEAR
              ? `The device ${itemSpecName} '${label}' is not included in your current plan.`
              : `Item ${itemSpecName} '${label}' is not supported by your plan. Please select a different item or contact support to upgrade your plan.`
          )
        } else if (itemSpecRestriction.remainder === 0) {
          setReadableError(
            WHITE_LABEL_BRAND_NAME === CLEAR
              ? `You've reached the maximum limit of devices which can be added to your account. Please contact support if you need assistance making updates to your account.`
              : `You have already reached the maximum number of allowed units for item ${itemSpecName} '${label}'. Please remove a device of the same kind or contact support to upgrade your plan.`
          )
        }
      }
    }
  }

  const onChangeItemType = (v) => {
    const tmpItemMakes = itemTypes.find((it) => it.id === v.value).item_makes
    validateSelectedItemAttribute(v.value, 'type', v.label)
    setItemMakes(tmpItemMakes)
    // when this change, make sure clean the last selector
    setItemModels([])
  }

  const onChangeItemMake = (v) => {
    const tmpItemModels = itemMakes.find((im) => im.id === v.value)
    validateSelectedItemAttribute(v.value, 'make', v.label)
    if (tmpItemModels) {
      setItemModels(tmpItemModels.item_models)
    } else {
      setItemModels([])
    }
  }

  const onChangeModelMake = (v) => {
    validateSelectedItemAttribute(v.value, 'model', v.label)
  }

  const mobileOperators = useSelector(selectMobileOperatorsForSelector)
  const purchaseConditions = useSelector(selectPurchaseConditionsForSelector)

  const currentDevice = useSelector((state) =>
    selectUserDeviceById(state, Number(itemId))
  )

  const initialValues = generateInitialValues(itemId, currentDevice)
  const onChangeFields = (values) => {}
  const onSubmitForm = (values) => {
    const payload = {
      id: itemId,
      item_category: category.id,
      type: values.kind.toLowerCase(),
      item_type: values.kind_id,
      make: values.brand,
      item_make: values.brand_id,
      model: values.model,
      item_model: values.model_id,
      condition: values.condition_purchased_id || undefined,
      purchase_date: values?.date_purchased
        ? moment(values.date_purchased).format('YYYY-MM-DD')
        : undefined,
      price: Number(values.purchase_price),
      serial_number: values.serial_number,
      nickname: values.nickname,
      purchased_at: values.location_purchased,
      // conditionals
      carrier_name: values.carrier,
      carrier: isEmpty(values.carrier_id) ? null : values.carrier_id,
      imei: values.imei || undefined,
      is_touch_screen: values.is_touch_screen,
      installments: values.installments,
      device_identifier: values.model_number || '',
      screen_size: Number(values.screen_size) || 0,
      storage: Number(values.storage) || 0,
      smart_tv: values.smart_tv,
    }
    if (itemId) {
      dispatch(updateItemDevice(payload))
        .unwrap()
        .then(() => {
          dispatch(getDeviceRestrictions()).unwrap().then(() => {
            navigate(`/${AFFECTED_ITEMS_PATH}/`)
          })
        })
    } else {
      dispatch(addItemDevice(payload))
        .unwrap()
        .then(() => {
          dispatch(getDeviceRestrictions()).unwrap().then(() => {
            navigate(`/${AFFECTED_ITEMS_PATH}/`)
          })
        })
    }
  }
  useEffect(() => {
    if (itemId) {
      // if there is itemId, it measn there is currentDevice
      const tmpItemMakes = itemTypes.find(
        (it) => it.id === currentDevice.item_type_id
      )?.item_makes
      const tmpItemModels = tmpItemMakes.find(
        (it) => it.id === currentDevice.item_make_id
      )?.item_models
      setItemMakes(tmpItemMakes || [])
      setItemModels(tmpItemModels || [])
    }
  }, [])
  return (
    <div className={c('item-detail', className)} {...props}>
      {edit && <div className="row">Edit</div>}
      <Formik
        initialValues={initialValues}
        validationSchema={ITEM_SCHEMA}
        validate={onChangeFields}
        onSubmit={onSubmitForm}
      >
        {({ values, setFieldValue, setValues, errors }) => (
          <>
            {readableError != null && (
              <div>
                <div className="modal show" style={{ display: 'block' }}>
                  <div
                    style={{ maxWidth: '48rem' }}
                    className="mt-4 mt-lg-5 mb-5 bg-white border rounded shadow-sm mx-auto"
                  >
                    <div className="p-3 d-flex justify-content-between align-items-center">
                      {WHITE_LABEL_BRAND_NAME === CLEAR ? (
                        <h3 className="mb-0">
                          Device Not Eligable for Plan Coverage
                        </h3>
                      ) : (
                        <h3 className="mb-0">
                          Unable to Add Device to Account
                        </h3>
                      )}
                    </div>

                    <hr className="opacity-10" />
                    <div className="p-3">
                      <div>
                        <div className="mb-4">{readableError}</div>
                      </div>
                    </div>

                    <hr className="opacity-10" />
                    <div className="p-3 d-flex flex-wrap justify-content-end">
                      <div className="me-3">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            setValues({
                              ...initialValues,
                            })
                            setReadableError(undefined)
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-backdrop fade show" />
              </div>
            )}
            <Form>
              <div className="row">
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    ITEM TYPE{markRequiredField('kind')}
                  </div>
                  <div className="item-detail_input">
                    <ItemType
                      itemId={Number(itemId)}
                      disabled={!isEmpty(itemId)}
                      options={itemTypes}
                      current={{ label: values.kind, value: values.kind_id }}
                      onChange={(v) => {
                        onChangeItemType(v)
                        setValues({
                          ...initialValues, // reset the form
                          kind: v.label,
                          kind_id: v.value,
                          nickname: `My ${v.label}`,
                        })
                      }}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="kind"
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    ITEM BRAND{markRequiredField('brand')}
                  </div>
                  <div className="item-detail_input">
                    <ItemMake
                      itemId={Number(itemId)}
                      options={itemMakes}
                      current={{ label: values.brand, value: values.brand_id }}
                      disabled={isEmpty(values.kind) || !isEmpty(itemId)}
                      onChange={(v) => {
                        onChangeItemMake(v)
                        setValues({
                          ...values,
                          brand: v.label,
                          brand_id: v.value,
                          model: '',
                          model_id: '',
                        })
                      }}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="brand"
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    ITEM MODEL{markRequiredField('model')}
                  </div>
                  <div className="item-detail_input">
                    <ItemModel
                      itemId={Number(itemId)}
                      disabled={isEmpty(values.brand) || !isEmpty(itemId)}
                      options={itemModels}
                      current={{ label: values.model, value: values.model_id }}
                      onChange={(v) => {
                        onChangeModelMake(v)
                        setValues({
                          ...values,
                          model: v.label,
                          model_id: v.value,
                        })
                      }}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="model"
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    PURCHASE PRICE{markRequiredField('purchase_price')}
                  </div>
                  <div className="item-detail_input">
                    <NumberFormat
                      className="form-control"
                      onValueChange={({ value }) =>
                        setFieldValue('purchase_price', value)
                      }
                      prefix="$ "
                      decimalScale={2}
                      value={values.purchase_price}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="purchase_price"
                  />
                </div>
                {values.kind === 'Laptop' ? (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="item-detail_label">
                      MODEL NUMBER{markRequiredField('model_number')}
                    </div>
                    <div className="item-detail_input">
                      <Field
                        className="form-control"
                        type="text"
                        name="model_number"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      className="error-text"
                      name="model_number"
                    />
                  </div>
                ) : null}
                {values.kind === 'Laptop' || values.kind === 'TV' ? (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="item-detail_label">
                      SCREEN SIZE{markRequiredField('screen_size')}
                    </div>
                    <div className="item-detail_input">
                      <NumberFormat
                        className="form-control"
                        onValueChange={({ value }) =>
                          setFieldValue('screen_size', value)
                        }
                        suffix="'"
                        value={values.screen_size}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      className="error-text"
                      name="screen_size"
                    />
                  </div>
                ) : null}
                {values.kind === 'TV' ? (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="item-detail_label">
                      IS SMART TV{markRequiredField('smart_tv')}
                    </div>
                    <div className="item-detail_input">
                      <Toggle
                        selected={values.smart_tv}
                        onChange={(value) => {
                          setFieldValue('smart_tv', value)
                        }}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      className="error-text"
                      name="smart_tv"
                    />
                  </div>
                ) : null}
                {values.kind === 'Laptop' ? (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="item-detail_label">
                      IS TOUCH SCREEN{markRequiredField('is_touch_screen')}
                    </div>
                    <div className="item-detail_input">
                      <Toggle
                        selected={values.is_touch_screen}
                        onChange={(value) => {
                          setFieldValue('is_touch_screen', value)
                        }}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      className="error-text"
                      name="is_touch_screen"
                    />
                  </div>
                ) : null}
                {values.kind === 'Phone' || values.kind === 'Laptop' ? (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="item-detail_label">
                      STORAGE{markRequiredField('storage')}
                    </div>
                    <div className="item-detail_input">
                      <NumberFormat
                        className="form-control"
                        onValueChange={({ value }) =>
                          setFieldValue('storage', value)
                        }
                        suffix="&nbsp;GB"
                        value={values.storage}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      className="error-text"
                      name="storage"
                    />
                  </div>
                ) : null}
                {values.kind === 'Phone' ? (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="item-detail_label">
                      CARRIER{markRequiredField('carrier')}
                    </div>
                    <div className="item-detail_input">
                      <Select
                        className="multiselect"
                        placeholder="Select carrier"
                        options={mobileOperators}
                        value={
                          isEmpty(values.carrier)
                            ? null
                            : {
                                value: values.carrier_id,
                                label: values.carrier,
                              }
                        }
                        onChange={({ label, value }) => {
                          setValues({
                            ...values,
                            carrier_id: value,
                            carrier: label,
                          })
                        }}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      className="error-text"
                      name="carrier"
                    />
                  </div>
                ) : null}
                {values.kind === 'Phone' ? (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="item-detail_label">
                      DO YOU PAY IN INSTALLMENTS?
                      {markRequiredField('installments')}
                    </div>
                    <div className="item-detail_input">
                      <Toggle
                        selected={values.installments}
                        onChange={(value) => {
                          setFieldValue('installments', value)
                        }}
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      className="error-text"
                      name="installments"
                    />
                  </div>
                ) : null}
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    CONDITION WHEN PURCHASED
                    {markRequiredField('condition_purchased')}
                  </div>
                  <div className="item-detail_input">
                    <Select
                      className="multiselect"
                      placeholder="Select Condition"
                      options={purchaseConditions}
                      value={
                        isEmpty(values.condition_purchased)
                          ? null
                          : {
                              label: values.condition_purchased,
                              value: values.condition_purchased_id,
                            }
                      }
                      onChange={({ label, value }) => {
                        setValues({
                          ...values,
                          condition_purchased: label,
                          condition_purchased_id: value,
                        })
                      }}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="condition_purchased"
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    WHERE WAS IT PURCHASED?
                    {markRequiredField('location_purchased')}
                  </div>
                  <div className="item-detail_input">
                    <Field
                      className="form-control"
                      type="text"
                      name="location_purchased"
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="location_purchased"
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    WHEN WAS IT PURCHASED?{markRequiredField('date_purchased')}
                  </div>
                  <div className="item-detail_input date-picker-input">
                    <DatePicker
                      className="form-control"
                      selected={values.date_purchased}
                      maxDate={new Date()}
                      onChange={(date) => {
                        setFieldValue('date_purchased', date)
                      }}
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="date_purchased"
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    SERIAL NUMBER{markRequiredField('serial_number')}
                  </div>
                  <div className="item-detail_input">
                    <Field
                      className="form-control"
                      type="text"
                      name="serial_number"
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="serial_number"
                  />
                </div>
                {values.kind === 'Phone' ? (
                  <div className="col-md-6 col-12 mb-3">
                    <div className="item-detail_label">
                      IMEI{markRequiredField('imei')}
                    </div>
                    <div className="item-detail_input">
                      <Field className="form-control" type="text" name="imei" />
                    </div>
                    <ErrorMessage
                      component="div"
                      className="error-text"
                      name="imei"
                    />
                  </div>
                ) : null}
                <div className="col-md-6 col-12 mb-3">
                  <div className="item-detail_label">
                    NICKNAME{markRequiredField('nickname')}
                  </div>
                  <div className="item-detail_input">
                    <Field
                      className="form-control"
                      type="text"
                      name="nickname"
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    className="error-text"
                    name="nickname"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {proccessing ? (
                    <Spinner />
                  ) : (
                    <div className="item-detail_btns">
                      <button type="submit" className="btn btn-primary">
                        {itemId ? 'Update Item' : 'Add Item'}
                      </button>
                      <Link
                        to={
                          itemId
                            ? `/${AFFECTED_ITEMS_PATH}/${claimID || ''}`
                            : `/${ADD_ITEMS_PATH}`
                        }
                        className="btn btn-outline-primary"
                      >
                        Go Back
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}
export default ItemDetail

ItemDetail.propTypes = {
  className: PropTypes.string,
  edit: PropTypes.bool,
}

ItemDetail.defaultProps = {
  className: '',
  edit: false,
}
