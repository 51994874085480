import { createSelector } from '@reduxjs/toolkit'

export const mobileOperatorsStore = (state) => state.mobileOperators

export const selectMobileOperators = createSelector(
  mobileOperatorsStore,
  (mobileOperators) => mobileOperators.list
)

export const selectMobileOperatorsForSelector = createSelector(
  selectMobileOperators,
  (mobileOperators) =>
    mobileOperators.map((mo) => ({ label: mo.name, value: mo.id }))
)
