import { createSelector } from '@reduxjs/toolkit'

export const purchaseConditionStore = (state) => state.purchaseConditions

export const selectPurchaseConditions = createSelector(
  purchaseConditionStore,
  (purchaseConditions) => purchaseConditions.list
)

export const selectPurchaseConditionsForSelector = createSelector(
  selectPurchaseConditions,
  (purchaseConditions) =>
    purchaseConditions.map((i) => ({
      value: i.id,
      label: i.description,
    }))
)
