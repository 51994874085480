import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_DEVICE_RESTRICTIONS } from "../constants"

export const getDeviceRestrictions = createAsyncThunk(
  "restrictions/getDeviceRestrictions",
  async (payload, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI
    try {
      const state = getState()
      const { user } = state
      const response = await axios.get(
        GET_DEVICE_RESTRICTIONS(user.user_id, user.contract[0].id),
      )
      return response.data?.[0]?.protection_plan?.device_restrictions
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const initialState = {
  deviceRestrictions: [],
}

export const restrictionsStore = createSlice({
  name: "restrictions",
  initialState: { ...initialState },
  reducers: {
    reset: (state, action) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getDeviceRestrictions.pending, (state, action) => {})
    builder.addCase(getDeviceRestrictions.fulfilled, (state, action) => {
      const { payload } = action
      console.log(payload)
      state.deviceRestrictions = payload
    })
    builder.addCase(getDeviceRestrictions.rejected, (state, action) => {
      state.deviceRestrictions = []
    })
  },
})

export default restrictionsStore.reducer
