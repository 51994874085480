import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import c from 'classnames'
import isEmpty from 'lodash/isEmpty'
import debounce from 'lodash/debounce'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

const getStreetAddressDetails = (addressComponents) => {
  const userAddressDetail = addressComponents.reduce((acc, component) => {
    if (component.types.includes('street_number')) {
      acc.street_number = component.long_name
    } else if (component.types.includes('route')) {
      acc.street = component.long_name
    } else if (component.types.includes('locality')) {
      acc.city = component.long_name
    } else if (component.types.includes('administrative_area_level_1')) {
      acc.state = component.long_name
    } else if (component.types.includes('country')) {
      acc.country = component.long_name
    } else if (component.types.includes('postal_code')) {
      acc.zip = component.long_name
    } else if (component.types.includes('sublocality')) {
      if(acc.city == null) {
        acc.city = component.long_name
      }
    }
    return acc
  }, {})
  const fullAddress = [
    `${userAddressDetail.street_number} ${userAddressDetail.street}`,
    userAddressDetail.city,
    userAddressDetail.state,
    userAddressDetail.zip,
    userAddressDetail.country,
  ].join(', ')
  return {
    ...userAddressDetail,
    address: fullAddress,
  }
}

export function GoogleAddress({
  className,
  value = '',
  onChange,
  onSelectAddress,
  placesType,
  disabled,
  css,
  debounceTime,
}) {
  const [location, setLocation] = useState(value)
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    // isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GM_KEY,
    region: 'us',
  })

  const callGooglePredictions = useCallback(
    debounce((locationName) => {
      getPlacePredictions({ input: locationName, searchTypes: [placesType] })
    }, debounceTime),
    []
  )

  const onChangeAddress = (e) => {
    const locationName = e.target.value
    setLocation(locationName)
    callGooglePredictions(locationName)
    onChange(locationName)
  }

  const onSelectAddress_ = (placeId) => {
    placesService.getDetails({ placeId }, (placeDetails) => {
      const address = getStreetAddressDetails(placeDetails.address_components)
      setLocation(placeDetails.formatted_address)
      getPlacePredictions({})
      onSelectAddress(address, placeDetails.address_components, placeId)
    })
  }

  return (
    <div className={c('google-places', className)}>
      <input
        className={`form-control ${css.input}`}
        type="text"
        onChange={onChangeAddress}
        placeholder="Enter your Address"
        value={location}
        disabled={disabled}
      />
      {!isEmpty(placePredictions) && (
        <div className={css.container}>
          <div className={css.options}>
            {placePredictions.map((r) => (
              <button
                className={css.option}
                key={r.place_id}
                type="button"
                onClick={() => onSelectAddress_(r.place_id)}
              >
                {r.description}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default GoogleAddress

GoogleAddress.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  placesType: PropTypes.string,
  onChange: PropTypes.func,
  onSelectAddress: PropTypes.func,
  disabled: PropTypes.bool,
  css: PropTypes.object,
  debounceTime: PropTypes.number,
}

GoogleAddress.defaultProps = {
  className: '',
  value: '',
  placesType: 'address',
  onChange: () => {},
  onSelectAddress: () => {},
  disabled: false,
  css: {
    input: 'form-control google-places_input',
    container: 'google-places_opt-container',
    options: 'google-places_options',
    option: 'google-places_option',
  },
  debounceTime: 700,
}
